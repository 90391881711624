import { Button, ButtonProps } from '@mui/material';
import { IconType } from '@src/components/shared/Utils';
import useBlockActionContext from '@src/store/BlockActionContext/BlockActionContext.hook';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDirtyContext } from '../hierarchies/shared/DirtyContext';

type ListItemLinkProps = {
    icon?: IconType;
    label: string;
    to: string;
    variant?: ButtonProps['variant'];
    color?: ButtonProps['color'];
    disabled?: boolean;
};

const ListItemLink = ({
    icon: Icon,
    label,
    to,
    disabled,
    variant,
    color,
}: ListItemLinkProps): JSX.Element => {
    const location = useLocation();
    const navigate = useNavigate();
    const { blockAction } = useBlockActionContext();
    const { isDirty } = useDirtyContext();

    const active = location.pathname.includes(to);

    const handleClick = () => {
        blockAction(() => navigate(to), isDirty);
    };

    return (
        <Button
            color={color || 'inherit'}
            disabled={disabled}
            startIcon={Icon ? <Icon /> : null}
            sx={theme => ({
                marginRight: theme.spacing(1),
            })}
            variant={variant || (active ? 'outlined' : 'text')}
            onClick={handleClick}
        >
            {label}
        </Button>
    );
};

export default ListItemLink;
