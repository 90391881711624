export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  NodeValue: any;
};

export type BreadcrumbNode = {
  __typename?: 'BreadcrumbNode';
  code: Scalars['String'];
  id: Scalars['String'];
  listName?: Maybe<Scalars['String']>;
};

export type BulkNodeContent = {
  children?: InputMaybe<Array<BulkNodeContent>>;
  code: Scalars['String'];
  contents: Array<NodeContent>;
  parent: Scalars['String'];
};

export type BulkNodes = {
  contents: Array<NodeContent>;
  node: Scalars['String'];
};

export type CertificationResponse = {
  __typename?: 'CertificationResponse';
  documentId: Scalars['String'];
};

export type CertificationStatus = {
  __typename?: 'CertificationStatus';
  certified: Scalars['String'];
  certifiedDraft: Scalars['String'];
  draft: Scalars['String'];
  error: Scalars['String'];
  inCertification: Scalars['String'];
};

export type CgtModelVariables = {
  base_program: Scalars['Float'];
  biased: Scalars['Float'];
  c1: Scalars['Float'];
  c2: Scalars['Float'];
  c3: Scalars['Float'];
  cash_discount: Scalars['Float'];
  cgtModelId: Scalars['String'];
  conquest: Scalars['Float'];
  cva: Scalars['Float'];
  dealer_discount: Scalars['Float'];
  extra_value_support: Scalars['Float'];
  gp_requested: Scalars['Float'];
  incentive_volume: Scalars['Float'];
  lc: Scalars['Float'];
  sv: Scalars['Float'];
};

export type CgtSettings = {
  __typename?: 'CgtSettings';
  fields: FieldsSetting;
  glHierarchyObjectSchemaId: Scalars['String'];
  groups: GroupsSetting;
  hierarchies: HierarchiesSetting;
  longListsHierarchyObjectSchemaId: Scalars['String'];
  paths: PathsSetting;
  prototypeSetting: PrototypeSetting;
  sapProductHierarchyObjectSchemaId: Scalars['String'];
  shortListFamilyHierarchyObjectSchemaId: Scalars['String'];
  skipPublish: Scalars['Boolean'];
};

export type ContentType = {
  __typename?: 'ContentType';
  code: Scalars['String'];
  creatable?: Maybe<Scalars['Boolean']>;
  depthFilter?: Maybe<Scalars['String']>;
  disableNodeCreation?: Maybe<Scalars['Boolean']>;
  dynamicFilters?: Maybe<Array<Filters>>;
  dynamicRegex?: Maybe<Array<DynamicRegex>>;
  enableRange?: Maybe<Scalars['Boolean']>;
  filters?: Maybe<Array<Filters>>;
  hidden?: Maybe<Scalars['Boolean']>;
  hierarchy?: Maybe<Scalars['String']>;
  isMainImage?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Scalars['String']>;
  link?: Maybe<Array<Maybe<Scalars['String']>>>;
  mandatory: Scalars['Boolean'];
  mandatoryForSave?: Maybe<MandatoryForSaveCondition>;
  multiLanguage: Scalars['Boolean'];
  multiLine?: Maybe<Scalars['Boolean']>;
  multiValue: Scalars['Boolean'];
  name: Array<NameObject>;
  optionalValues?: Maybe<Array<SelectOptionValues>>;
  readOnly?: Maybe<Scalars['Boolean']>;
  regex?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  visibility?: Maybe<Visibility>;
};

export type ContentTypeGroup = {
  __typename?: 'ContentTypeGroup';
  approveRoles?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  collapsed?: Maybe<Scalars['Boolean']>;
  contentTypes?: Maybe<Array<ContentType>>;
  name: Array<NameObject>;
  repeatable: Scalars['Boolean'];
  roles?: Maybe<Scalars['String']>;
  showLevel?: Maybe<Scalars['String']>;
};

export type ContentTypeGroupI = {
  approveRoles?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  collapsed: Scalars['Boolean'];
  name: Array<NameObjectI>;
  repeatable: Scalars['Boolean'];
  roles?: InputMaybe<Scalars['String']>;
  showLevel?: InputMaybe<Scalars['String']>;
};

export type ContentTypeGroupStatus = {
  __typename?: 'ContentTypeGroupStatus';
  code: Scalars['String'];
  publishedAt?: Maybe<Scalars['String']>;
  status: CtgStatus;
};

export type ContentTypeInput = {
  code: Scalars['String'];
  creatable?: InputMaybe<Scalars['Boolean']>;
  depthFilter?: InputMaybe<Scalars['String']>;
  disableNodeCreation?: InputMaybe<Scalars['Boolean']>;
  dynamicFilters?: InputMaybe<Array<FiltersInput>>;
  dynamicRegex?: InputMaybe<Array<DynamicRegexInput>>;
  enableRange: Scalars['Boolean'];
  filters?: InputMaybe<Array<FiltersInput>>;
  hidden: Scalars['Boolean'];
  hierarchy?: InputMaybe<Scalars['String']>;
  isMainImage?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Array<Scalars['String']>>;
  mandatory: Scalars['Boolean'];
  mandatoryForSave?: InputMaybe<MandatoryForSaveConditionInput>;
  multiLanguage: Scalars['Boolean'];
  multiLine?: InputMaybe<Scalars['Boolean']>;
  multiValue: Scalars['Boolean'];
  name: Array<NameObjectI>;
  optionalValues?: InputMaybe<Array<SelectOptionValuesInput>>;
  readOnly: Scalars['Boolean'];
  regex?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  visibility: VisibilityInput;
};

export enum CtgStatus {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  NotPublishable = 'NOT_PUBLISHABLE',
  Published = 'PUBLISHED',
  PublishedDraft = 'PUBLISHED_DRAFT'
}

export type DuplicatedCode = {
  __typename?: 'DuplicatedCode';
  code?: Maybe<Scalars['String']>;
};

export type DynamicRegex = {
  __typename?: 'DynamicRegex';
  comparator: Scalars['String'];
  id: Scalars['String'];
  option: Scalars['String'];
  regex: Scalars['String'];
  value: Scalars['String'];
};

export type DynamicRegexInput = {
  comparator: Scalars['String'];
  id: Scalars['String'];
  option: Scalars['String'];
  regex: Scalars['String'];
  value: Scalars['String'];
};

export type Edit = {
  __typename?: 'Edit';
  contentType: Scalars['String'];
  contentTypeGroup: Scalars['String'];
  type: Scalars['String'];
  value: Scalars['NodeValue'];
};

export type ExportJobFinishedInfo = {
  __typename?: 'ExportJobFinishedInfo';
  filename?: Maybe<Scalars['String']>;
};

export type Family = {
  __typename?: 'Family';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type FieldSetting = {
  __typename?: 'FieldSetting';
  contentTypeCode: Scalars['String'];
  defaultValue?: Maybe<Scalars['String']>;
};

export type FieldsSetting = {
  __typename?: 'FieldsSetting';
  baseProgramField: FieldSetting;
  biasedField: FieldSetting;
  c1Field: FieldSetting;
  c2Field: FieldSetting;
  c3Field: FieldSetting;
  cashDiscountField: FieldSetting;
  certificationStatusField: FieldSetting;
  conquestField: FieldSetting;
  cvaField: FieldSetting;
  dealerDiscountField: FieldSetting;
  denominationField: FieldSetting;
  descriptionField: FieldSetting;
  extraValueSupportField: FieldSetting;
  familyField: FieldSetting;
  flagUseDN: FieldSetting;
  glBaseMachineField: FieldSetting;
  gpRequestedField: FieldSetting;
  incentiveVolumeField: FieldSetting;
  installedDealerNetField: FieldSetting;
  itemsField: FieldSetting;
  lcField: FieldSetting;
  lineaSapField: FieldSetting;
  marcaField: FieldSetting;
  modelCodeField: FieldSetting;
  notInstalledDealerNetField: FieldSetting;
  orderTypeField: FieldSetting;
  priceListField: FieldSetting;
  sapProductHierarchyField: FieldSetting;
  selectionsField: FieldSetting;
  suffixField: FieldSetting;
  supplierField: FieldSetting;
  supplierNameField: FieldSetting;
  svField: FieldSetting;
  typeField: FieldSetting;
};

export type Filters = {
  __typename?: 'Filters';
  code: Scalars['String'];
  comparator: SearchFilterOperator;
  editable?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  value: Scalars['String'];
};

export type FiltersInput = {
  code: Scalars['String'];
  comparator: Scalars['String'];
  editable: Scalars['Boolean'];
  id: Scalars['String'];
  value: Scalars['String'];
};

export type GlCharacteristic = {
  __typename?: 'GlCharacteristic';
  code: Scalars['String'];
  label: Scalars['String'];
  options: Array<GlCharacteristicOption>;
};

export type GlCharacteristicOption = {
  __typename?: 'GlCharacteristicOption';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type GroupSetting = {
  __typename?: 'GroupSetting';
  contentTypeGroupCode: Scalars['String'];
};

export type GroupsSetting = {
  __typename?: 'GroupsSetting';
  catSupportGroup: GroupSetting;
  cgtSupportGroup: GroupSetting;
  pricingGroup: GroupSetting;
  sapCharacteristicsGroup: GroupSetting;
};

export type HierarchiesSetting = {
  __typename?: 'HierarchiesSetting';
  cGTModelCompetitorHierarchyId: Scalars['String'];
  cGTModelHierarchyId: Scalars['String'];
  cGTModelUsatoHierarchyId: Scalars['String'];
  commercialFamilyHierarchyId: Scalars['String'];
  glCompetitorHierarchyId: Scalars['String'];
  glExFlottaHierarchyId: Scalars['String'];
  glGenericHierarchyId: Scalars['String'];
  glHierarchyId: Scalars['String'];
  glUsatoHierarchyId: Scalars['String'];
  longListsHierarchyId: Scalars['String'];
  marcheHierarchyId: Scalars['String'];
  priceListHierarchyId: Scalars['String'];
  sapProductCompetitorHierarchyId: Scalars['String'];
  sapProductHierarchyId: Scalars['String'];
  sezioniSchedeHierarchyId: Scalars['String'];
  shortListFamilyHierarchyId: Scalars['String'];
  supplierHierarchyId: Scalars['String'];
};

export type Hierarchy = {
  __typename?: 'Hierarchy';
  code: Scalars['String'];
  id: Scalars['String'];
  nodes: Array<HierarchyNode>;
  objectSchema?: Maybe<ObjectSchema>;
};


export type HierarchyNodesArgs = {
  filters?: InputMaybe<NodeFilter>;
  isInTranslation?: InputMaybe<Scalars['Boolean']>;
  pagination?: InputMaybe<Pagination>;
};

export type HierarchyHistoryEdge = {
  __typename?: 'HierarchyHistoryEdge';
  cursor: Scalars['String'];
  node: HierarchyHistoryNode;
};

export type HierarchyHistoryNode = {
  __typename?: 'HierarchyHistoryNode';
  code: Scalars['String'];
  contents: Array<NodeContentO>;
  disabled: Scalars['Boolean'];
  eventId: Scalars['String'];
  eventName: Scalars['String'];
  parent: Scalars['String'];
  timestamp: Scalars['String'];
  user: Scalars['String'];
};

export type HierarchyHistoryResult = {
  __typename?: 'HierarchyHistoryResult';
  edges: Array<HierarchyHistoryEdge>;
  objectSchema: ObjectSchema;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type HierarchyNode = {
  __typename?: 'HierarchyNode';
  certificationStatus?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  contentTypeGroupStatus?: Maybe<Array<ContentTypeGroupStatus>>;
  contents?: Maybe<Array<NodeContentO>>;
  disabled?: Maybe<Scalars['Boolean']>;
  hasChildren?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  linkedBy?: Maybe<Array<Maybe<LinkedBy>>>;
  listName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  parent: Scalars['String'];
  parentCode?: Maybe<Scalars['String']>;
  publishedContents?: Maybe<Array<NodeContentO>>;
  readRoles?: Maybe<Array<Scalars['String']>>;
  totalChildren?: Maybe<Scalars['Int']>;
  writeRoles?: Maybe<Array<Scalars['String']>>;
};

export type IsoCode = {
  __typename?: 'IsoCode';
  approverRole?: Maybe<Scalars['String']>;
  isoCode: Scalars['String'];
  name: Scalars['String'];
  translatorRole?: Maybe<Scalars['String']>;
};

export type Language = {
  __typename?: 'Language';
  defaultLanguage: Scalars['String'];
  languages: Array<IsoCode>;
};

export type LanguageInput = {
  approverRole?: InputMaybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  isoCode: Scalars['String'];
  name: Scalars['String'];
  translatorRole?: InputMaybe<Scalars['String']>;
};

export type LegendItem = {
  __typename?: 'LegendItem';
  code: Scalars['String'];
  value: Scalars['String'];
};

export type LegendItemInput = {
  code: Scalars['String'];
  value: Scalars['String'];
};

export type LinkedBy = {
  __typename?: 'LinkedBy';
  code: Scalars['String'];
  hierarchyId: Scalars['String'];
  listName?: Maybe<Scalars['String']>;
  nodeId: Scalars['String'];
  order?: Maybe<Scalars['String']>;
};

export type MmDispoValidity = {
  __typename?: 'MMDispoValidity';
  errors: Array<PrototypeMmDispoError>;
  prototypeCode: Scalars['String'];
};

export type MandatoryForSaveCondition = {
  __typename?: 'MandatoryForSaveCondition';
  comparator?: Maybe<Scalars['String']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  option?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type MandatoryForSaveConditionInput = {
  comparator?: InputMaybe<Scalars['String']>;
  isMandatory: Scalars['Boolean'];
  option?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type MisalignedGlData = {
  __typename?: 'MisalignedGlData';
  code: Scalars['String'];
  hierarchyId: Scalars['String'];
  misalignmentType: MisalignmentType;
  nodeId: Scalars['String'];
  replacedItemCode?: Maybe<Scalars['String']>;
};

export enum MisalignmentType {
  Price = 'PRICE',
  Replacement = 'REPLACEMENT'
}

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  addContentType?: Maybe<ObjectSchemaMutationResponse>;
  addContentTypeGroup?: Maybe<ObjectSchemaMutationResponse>;
  addLanguage?: Maybe<ObjectSchemaMutationResponse>;
  alignLongLists?: Maybe<ObjectSchemaMutationResponse>;
  alignLonglistsSections?: Maybe<ObjectSchemaMutationResponse>;
  alignModelGls?: Maybe<ObjectSchemaMutationResponse>;
  approveContentTypeGroup?: Maybe<ObjectSchemaMutationResponse>;
  certificateModel?: Maybe<ObjectSchemaMutationResponse>;
  certificatePrototype?: Maybe<CertificationResponse>;
  changeNodesOrder?: Maybe<ObjectSchemaMutationResponse>;
  contentTranslationApproval?: Maybe<Scalars['Boolean']>;
  createHierarchy?: Maybe<ObjectSchemaMutationResponse>;
  createLongList?: Maybe<ObjectSchemaMutationResponse>;
  createNode?: Maybe<ObjectSchemaMutationResponse>;
  createObjectSchema?: Maybe<ObjectSchemaMutationResponse>;
  createPrototype?: Maybe<ObjectSchemaMutationResponse>;
  deleteContentType?: Maybe<ObjectSchemaMutationResponse>;
  deleteContentTypeGroup?: Maybe<ObjectSchemaMutationResponse>;
  deleteHierarchy?: Maybe<ObjectSchemaMutationResponse>;
  deleteLanguage?: Maybe<ObjectSchemaMutationResponse>;
  deleteNode?: Maybe<ObjectSchemaMutationResponse>;
  deleteObjectSchema?: Maybe<ObjectSchemaMutationResponse>;
  duplicateHierarchy?: Maybe<ObjectSchemaMutationResponse>;
  duplicateNode?: Maybe<ObjectSchemaMutationResponse>;
  duplicateObjectSchema?: Maybe<ObjectSchemaMutationResponse>;
  duplicatePrototype?: Maybe<ObjectSchemaMutationResponse>;
  exportXlsx?: Maybe<Scalars['String']>;
  migrateSidelHierarchyData?: Maybe<ObjectSchemaMutationResponse>;
  migrateSidelProductsData?: Maybe<ObjectSchemaMutationResponse>;
  publish?: Maybe<ObjectSchemaMutationResponse>;
  publishHierarchies?: Maybe<ObjectSchemaMutationResponse>;
  publishLongList: PublishResponse;
  reorderContentType?: Maybe<ObjectSchemaMutationResponse>;
  reorderContentTypeGroup?: Maybe<ObjectSchemaMutationResponse>;
  saveShortPricelist?: Maybe<ObjectSchemaMutationResponse>;
  saveShortPricelistFamily?: Maybe<ObjectSchemaMutationResponse>;
  toggleDisabled?: Maybe<ObjectSchemaMutationResponse>;
  updateBulkNodesContents?: Maybe<ObjectSchemaMutationResponse>;
  updateContentType?: Maybe<ObjectSchemaMutationResponse>;
  updateContentTypeCode?: Maybe<ObjectSchemaMutationResponse>;
  updateContentTypeGroup?: Maybe<ObjectSchemaMutationResponse>;
  updateContentTypeGroupCode?: Maybe<ObjectSchemaMutationResponse>;
  updateHierarchy?: Maybe<ObjectSchemaMutationResponse>;
  updateLanguage?: Maybe<ObjectSchemaMutationResponse>;
  updateListName?: Maybe<Scalars['Boolean']>;
  updateNode?: Maybe<ObjectSchemaMutationResponse>;
  updateNodeContent?: Maybe<ObjectSchemaMutationResponse>;
  updateNodeParent?: Maybe<ObjectSchemaMutationResponse>;
  updateObjectSchema?: Maybe<ObjectSchemaMutationResponse>;
  updatePermissions?: Maybe<ObjectSchemaMutationResponse>;
  updatePrototype?: Maybe<PrototypeUpdateMutationResponse>;
  updateTranslationStatistics?: Maybe<ObjectSchemaMutationResponse>;
};


export type MutationAddContentTypeArgs = {
  aggregateId: Scalars['String'];
  contentType: ContentTypeInput;
  groupCode: Scalars['String'];
};


export type MutationAddContentTypeGroupArgs = {
  aggregateId: Scalars['String'];
  contentTypeGroup: ContentTypeGroupI;
};


export type MutationAddLanguageArgs = {
  language: LanguageInput;
};


export type MutationAlignLongListsArgs = {
  prototypeId: Scalars['String'];
};


export type MutationAlignLonglistsSectionsArgs = {
  description: Scalars['String'];
  name: Scalars['String'];
  notes: Scalars['String'];
  sectionNodeId: Scalars['String'];
};


export type MutationAlignModelGlsArgs = {
  misalignedGlsIds: Array<Scalars['String']>;
  modelId: Scalars['String'];
};


export type MutationApproveContentTypeGroupArgs = {
  contentTypeGroup?: InputMaybe<Scalars['String']>;
  hierarchyId: Scalars['String'];
  nodeId: Scalars['String'];
};


export type MutationCertificateModelArgs = {
  aggregateId: Scalars['String'];
  nodeId: Scalars['String'];
};


export type MutationCertificatePrototypeArgs = {
  cgtModelVariables: CgtModelVariables;
  prototypeId: Scalars['String'];
};


export type MutationChangeNodesOrderArgs = {
  hierarchyId: Scalars['String'];
  nodeOrder: Array<OrderNode>;
  parentId: Scalars['String'];
};


export type MutationContentTranslationApprovalArgs = {
  contentType: Scalars['String'];
  contentTypeGroup: Scalars['String'];
  contentTypeGroupIndex: Scalars['Int'];
  hierarchyId: Scalars['String'];
  languageIsoCode: Scalars['String'];
  nodeId: Scalars['String'];
};


export type MutationCreateHierarchyArgs = {
  code: Scalars['String'];
  objectSchema: Scalars['String'];
};


export type MutationCreateLongListArgs = {
  aggregateId: Scalars['String'];
  commercialFamilyId: Scalars['String'];
  commercialFamilyParentId: Scalars['String'];
  prototypeId: Scalars['String'];
};


export type MutationCreateNodeArgs = {
  aggregateId: Scalars['String'];
  code: Scalars['String'];
  parent: Scalars['String'];
};


export type MutationCreateObjectSchemaArgs = {
  code: Scalars['String'];
  disableDelete: Scalars['Boolean'];
  disableEditCode: Scalars['Boolean'];
  listName: Scalars['String'];
};


export type MutationCreatePrototypeArgs = {
  modelName: Scalars['String'];
  prototypeCode: Scalars['String'];
};


export type MutationDeleteContentTypeArgs = {
  aggregateId: Scalars['String'];
  contentTypeCode: Scalars['String'];
  groupCode: Scalars['String'];
};


export type MutationDeleteContentTypeGroupArgs = {
  aggregateId: Scalars['String'];
  code: Scalars['String'];
};


export type MutationDeleteHierarchyArgs = {
  aggregateId: Scalars['String'];
};


export type MutationDeleteLanguageArgs = {
  isoCode: Scalars['String'];
};


export type MutationDeleteNodeArgs = {
  aggregateId: Scalars['String'];
  node: Scalars['String'];
};


export type MutationDeleteObjectSchemaArgs = {
  aggregateId: Scalars['String'];
};


export type MutationDuplicateHierarchyArgs = {
  aggregateId: Scalars['String'];
  code: Scalars['String'];
};


export type MutationDuplicateNodeArgs = {
  aggregateId: Scalars['String'];
  newCode: Scalars['String'];
  nodeId: Scalars['String'];
  parent: Scalars['String'];
  suffix: Scalars['String'];
};


export type MutationDuplicateObjectSchemaArgs = {
  aggregateId: Scalars['String'];
  code: Scalars['String'];
};


export type MutationDuplicatePrototypeArgs = {
  contents?: InputMaybe<Array<NodeContent>>;
  modelName: Scalars['String'];
  prototypeCode: Scalars['String'];
  prototypeId: Scalars['String'];
};


export type MutationExportXlsxArgs = {
  filter: Array<SearchFilter>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  nodeId?: InputMaybe<Scalars['String']>;
  sort: Array<Sort>;
  visibleFields?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationPublishArgs = {
  contentTypeGroup: Scalars['String'];
  hierarchy: Scalars['String'];
  node: Scalars['String'];
};


export type MutationPublishHierarchiesArgs = {
  hierarchies?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationPublishLongListArgs = {
  aggregateId: Scalars['String'];
  cgtModelVariables: CgtModelVariables;
  node: Scalars['String'];
  prototypeId: Scalars['String'];
};


export type MutationReorderContentTypeArgs = {
  aggregateId: Scalars['String'];
  groupCode: Scalars['String'];
  order: Array<Scalars['String']>;
};


export type MutationReorderContentTypeGroupArgs = {
  aggregateId: Scalars['String'];
  order: Array<Scalars['String']>;
};


export type MutationSaveShortPricelistArgs = {
  macroFamily?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  preset?: InputMaybe<Scalars['String']>;
};


export type MutationSaveShortPricelistFamilyArgs = {
  legend: Array<LegendItemInput>;
  name: Scalars['String'];
  prototypeIds: Array<Scalars['String']>;
  shortPricelistId: Scalars['String'];
};


export type MutationToggleDisabledArgs = {
  aggregateId: Scalars['String'];
  node: Scalars['String'];
};


export type MutationUpdateBulkNodesContentsArgs = {
  aggregateId: Scalars['String'];
  nodes: Array<BulkNodes>;
};


export type MutationUpdateContentTypeArgs = {
  aggregateId: Scalars['String'];
  groupCode: Scalars['String'];
  updateContentTypeInput: ContentTypeInput;
};


export type MutationUpdateContentTypeCodeArgs = {
  aggregateId: Scalars['String'];
  groupCode: Scalars['String'];
  newCode: Scalars['String'];
  oldCode: Scalars['String'];
};


export type MutationUpdateContentTypeGroupArgs = {
  aggregateId: Scalars['String'];
  updateContentTypeGroupInput: UpdateContentTypeGroupInput;
};


export type MutationUpdateContentTypeGroupCodeArgs = {
  aggregateId: Scalars['String'];
  newCode: Scalars['String'];
  oldCode: Scalars['String'];
};


export type MutationUpdateHierarchyArgs = {
  aggregateId: Scalars['String'];
  code: Scalars['String'];
  objectSchema: Scalars['String'];
};


export type MutationUpdateLanguageArgs = {
  language: LanguageInput;
  oldIsoCode: Scalars['String'];
};


export type MutationUpdateListNameArgs = {
  listName: Scalars['String'];
  objectSchema: Scalars['String'];
};


export type MutationUpdateNodeArgs = {
  aggregateId: Scalars['String'];
  code: Scalars['String'];
  node: Scalars['String'];
  parent?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateNodeContentArgs = {
  aggregateId: Scalars['String'];
  contents: Array<NodeContent>;
  isCreate?: InputMaybe<Scalars['Boolean']>;
  node: Scalars['String'];
  parent?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateNodeParentArgs = {
  aggregateId: Scalars['String'];
  node: Scalars['String'];
  parent: Scalars['String'];
};


export type MutationUpdateObjectSchemaArgs = {
  aggregateId: Scalars['String'];
  code: Scalars['String'];
  disableDelete: Scalars['Boolean'];
  disableEditCode: Scalars['Boolean'];
  listName: Scalars['String'];
};


export type MutationUpdatePermissionsArgs = {
  hierarchyId: Scalars['String'];
  nodeId: Scalars['String'];
  readRoles: Array<Scalars['String']>;
  writeRoles: Array<Scalars['String']>;
};


export type MutationUpdatePrototypeArgs = {
  aggregateId: Scalars['String'];
  contents: Array<NodeContent>;
  node: Scalars['String'];
};


export type MutationUpdateTranslationStatisticsArgs = {
  keptAsReceivedCount: Scalars['Int'];
  modifiedCount: Scalars['Int'];
};

export type NameObject = {
  __typename?: 'NameObject';
  languageIsoCode: Scalars['String'];
  value: Scalars['String'];
};

export type NameObjectI = {
  languageIsoCode: Scalars['String'];
  value: Scalars['String'];
};

export type NodeContent = {
  contentType: Scalars['String'];
  contentTypeGroup: Scalars['String'];
  contentTypeGroupIndex: Scalars['Int'];
  hierarchy?: InputMaybe<Scalars['String']>;
  repeatable: Scalars['Boolean'];
  type: Scalars['String'];
  value: Array<NodeContentValue>;
};

export type NodeContentFilter = {
  contentType?: InputMaybe<Scalars['String']>;
  contentTypeGroup?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type NodeContentO = {
  __typename?: 'NodeContentO';
  contentType: Scalars['String'];
  contentTypeGroup: Scalars['String'];
  contentTypeGroupIndex: Scalars['Int'];
  inherited: Scalars['Boolean'];
  repeatable?: Maybe<Scalars['Boolean']>;
  type: Scalars['String'];
  value?: Maybe<Array<NodeContentValueO>>;
};

export type NodeContentValue = {
  approvedValue?: InputMaybe<Array<Scalars['NodeValue']>>;
  code?: InputMaybe<Scalars['String']>;
  languageIsoCode: Scalars['String'];
  measure?: InputMaybe<Scalars['String']>;
  translate?: InputMaybe<Scalars['Boolean']>;
  translating?: InputMaybe<Scalars['Boolean']>;
  value: Array<Scalars['NodeValue']>;
};

export type NodeContentValueO = {
  __typename?: 'NodeContentValueO';
  approvedValue?: Maybe<Array<Maybe<Scalars['NodeValue']>>>;
  code?: Maybe<Scalars['String']>;
  languageIsoCode: Scalars['String'];
  measure?: Maybe<Scalars['String']>;
  translate?: Maybe<Scalars['Boolean']>;
  translating?: Maybe<Scalars['Boolean']>;
  value: Array<Maybe<Scalars['NodeValue']>>;
};

export type NodeFilter = {
  code?: InputMaybe<Scalars['String']>;
  contentFilters?: InputMaybe<Array<NodeContentFilter>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  parent?: InputMaybe<Scalars['String']>;
};

export type NodeResult = {
  __typename?: 'NodeResult';
  count: Scalars['Int'];
  edges: Array<SearchNodeEdge>;
  pageInfo: PageInfo;
};

export type ObjectSchema = {
  __typename?: 'ObjectSchema';
  code: Scalars['String'];
  contentTypeGroups?: Maybe<Array<ContentTypeGroup>>;
  disableDelete?: Maybe<Scalars['Boolean']>;
  disableEditCode?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isReferenced: Scalars['Boolean'];
  listName?: Maybe<Scalars['String']>;
};

export type ObjectSchemaMutationResponse = {
  __typename?: 'ObjectSchemaMutationResponse';
  code: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type OptionValue = {
  __typename?: 'OptionValue';
  languageIsoCode: Scalars['String'];
  value: Scalars['String'];
};

export type OptionValueInput = {
  languageIsoCode: Scalars['String'];
  value: Scalars['String'];
};

export type OrderNode = {
  nodeId: Scalars['String'];
  order: Scalars['Int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
};

export type Pagination = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type PathsSetting = {
  __typename?: 'PathsSetting';
  accessories: Scalars['String'];
  baseMachine: Scalars['String'];
  itemsValue: Scalars['String'];
  priceListValue: Scalars['String'];
  primaryPriceListValue: Scalars['String'];
  secondaryPriceListValue: Scalars['String'];
};

export type Price = {
  __typename?: 'Price';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type PriceSetting = {
  __typename?: 'PriceSetting';
  dealer_gp: Price;
  dealer_markup: Price;
  dealer_mo: Price;
  dealer_ms: Price;
  dealer_price: Price;
  dn: Price;
  dn_cash_d: Price;
  forced_gp: Price;
  forced_mo: Price;
  forced_tpa: Price;
  gl_list_price: Price;
  gl_tpa: Price;
  gp: Price;
  gp_ms: Price;
  gp_tpa: Price;
  lc: Price;
  list_price: Price;
  markup: Price;
  mo: Price;
  ms: Price;
  standard_price_delta: Price;
  tpa: Price;
  transport_costs: Price;
};

export type ProductInfoMutationType = {
  __typename?: 'ProductInfoMutationType';
  code: Scalars['String'];
  contentTypeGroups: Array<ContentTypeGroup>;
  id: Scalars['String'];
};

export type PrototypeMmDispoError = {
  __typename?: 'PrototypeMMDispoError';
  gls: Array<PrototypeMmDispoGl>;
  sapCharacteristicNumber: Scalars['Int'];
};

export type PrototypeMmDispoGl = {
  __typename?: 'PrototypeMMDispoGl';
  code: Scalars['String'];
  id: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type PrototypeSetting = {
  __typename?: 'PrototypeSetting';
  certificationStatuses: CertificationStatus;
  glCodes: Array<Scalars['String']>;
  modelCode: Scalars['String'];
  objectSchemaCode: Scalars['String'];
  priceCodes: PriceSetting;
  prototypeHierarchyId: Scalars['String'];
};

export type PrototypeUpdateMutationResponse = {
  __typename?: 'PrototypeUpdateMutationResponse';
  code: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type PublishResponse = {
  __typename?: 'PublishResponse';
  salesforceDocumentId: Scalars['String'];
};

export type PublishedContentTypeGroup = {
  __typename?: 'PublishedContentTypeGroup';
  contentTypeGroup: Scalars['String'];
  contents: Array<NodeContentO>;
};

export type PublishedNode = {
  __typename?: 'PublishedNode';
  contentTypeGroups?: Maybe<Array<PublishedContentTypeGroup>>;
  id?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  updatedBy: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  breadcrumb: Array<BreadcrumbNode>;
  contentTranslation: Scalars['String'];
  contentType?: Maybe<ContentType>;
  contentTypeGroup?: Maybe<ContentTypeGroup>;
  families: Array<Family>;
  getMisalignedModelGls: Array<MisalignedGlData>;
  getPrototypes: Array<HierarchyNode>;
  getPrototypesMMDispoValidity: Array<MmDispoValidity>;
  getPublishedContent: PublishedNode;
  getPublishedNodeVersions: Array<PublishedNode>;
  glCharacteristics: Array<GlCharacteristic>;
  glItems: DuplicatedCode;
  hierarchies?: Maybe<Array<Hierarchy>>;
  hierarchyHistory: HierarchyHistoryResult;
  inheritAncestorNode: Scalars['String'];
  languages?: Maybe<Array<Language>>;
  nodeList: SearchResult;
  objectSchemas: Array<ObjectSchema>;
  prices: Array<Price>;
  prototypeCount: Scalars['Int'];
  prototypeRoot?: Maybe<Hierarchy>;
  prototypes: SearchResult;
  searchHierarchies: SearchResult;
  settings: Setting;
  shortPricelists: Array<ShortPricelist>;
};


export type QueryBreadcrumbArgs = {
  hierarchyId?: InputMaybe<Scalars['String']>;
  nodeId?: InputMaybe<Scalars['String']>;
};


export type QueryContentTranslationArgs = {
  content: Scalars['String'];
  fromIsoCode: Scalars['String'];
  toIsoCode: Scalars['String'];
  type: Scalars['String'];
};


export type QueryContentTypeArgs = {
  aggregateId: Scalars['String'];
  fieldCode: Scalars['String'];
  groupCode: Scalars['String'];
};


export type QueryContentTypeGroupArgs = {
  aggregateId: Scalars['String'];
  code: Scalars['String'];
};


export type QueryGetMisalignedModelGlsArgs = {
  modelId: Scalars['String'];
};


export type QueryGetPrototypesArgs = {
  modelName: Scalars['String'];
};


export type QueryGetPrototypesMmDispoValidityArgs = {
  modelId: Scalars['String'];
};


export type QueryGetPublishedContentArgs = {
  contentTypeGroup?: InputMaybe<Scalars['String']>;
  hierarchy: Scalars['String'];
  node: Scalars['String'];
  version?: InputMaybe<Scalars['String']>;
};


export type QueryGetPublishedNodeVersionsArgs = {
  hierarchy: Scalars['String'];
  node: Scalars['String'];
};


export type QueryGlItemsArgs = {
  hierarchyId: Scalars['String'];
  itemValues: Array<InputMaybe<Scalars['NodeValue']>>;
  nodeId: Scalars['String'];
};


export type QueryHierarchiesArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryHierarchyHistoryArgs = {
  hierarchy: Scalars['String'];
  node: Scalars['String'];
  pagination: Pagination;
};


export type QueryInheritAncestorNodeArgs = {
  contentType: Scalars['String'];
  contentTypeGroup: Scalars['String'];
  contentTypeGroupIndex: Scalars['Int'];
  hierarchyId: Scalars['String'];
  nodeId: Scalars['String'];
};


export type QueryLanguagesArgs = {
  isoCode?: InputMaybe<Scalars['String']>;
};


export type QueryNodeListArgs = {
  filter: Array<SearchFilter>;
  id?: InputMaybe<Scalars['String']>;
  isInTranslation?: InputMaybe<Scalars['Boolean']>;
  nodeId?: InputMaybe<Scalars['String']>;
  pagination: Pagination;
};


export type QueryPricesArgs = {
  contentTypeGroupCode: Scalars['String'];
  hierarchyId: Scalars['String'];
};


export type QueryPrototypeCountArgs = {
  modelName: Scalars['String'];
};


export type QueryPrototypesArgs = {
  filter: Array<SearchFilter>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  pagination: Pagination;
  sort: Array<Sort>;
};


export type QuerySearchHierarchiesArgs = {
  filter: Array<SearchFilter>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  pagination: Pagination;
  sort: Array<Sort>;
};

export type SearchFilter = {
  contentFiltersToIgnore?: InputMaybe<Array<Scalars['String']>>;
  field: Scalars['String'];
  linkedHierarchy?: InputMaybe<Scalars['String']>;
  operator: SearchFilterOperator;
  value: Array<Scalars['String']>;
};

export enum SearchFilterOperator {
  Contains = 'contains',
  EndsWith = 'endsWith',
  Equals = 'equals',
  ExactMatch = 'exactMatch',
  HasLinkTo = 'hasLinkTo',
  Includes = 'includes',
  IsAnyOf = 'isAnyOf',
  IsEmpty = 'isEmpty',
  IsNotEmpty = 'isNotEmpty',
  NotEquals = 'notEquals',
  SiblingLinkCheck = 'siblingLinkCheck',
  StartsWith = 'startsWith'
}

export type SearchNode = {
  __typename?: 'SearchNode';
  breadcrumb?: Maybe<Array<BreadcrumbNode>>;
  code: Scalars['String'];
  contentTypeGroupStatus?: Maybe<Array<ContentTypeGroupStatus>>;
  contents: Array<NodeContentO>;
  disabled: Scalars['Boolean'];
  firstNodeStatus?: Maybe<Scalars['String']>;
  hierarchy: Scalars['String'];
  hierarchyCode: Scalars['String'];
  id: Scalars['String'];
  linkedBy?: Maybe<Array<Maybe<LinkedBy>>>;
  listName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  parent: Scalars['String'];
  parentCode: Scalars['String'];
  readRoles?: Maybe<Array<Scalars['String']>>;
  writeRoles?: Maybe<Array<Scalars['String']>>;
};

export type SearchNodeEdge = {
  __typename?: 'SearchNodeEdge';
  cursor: Scalars['String'];
  node: SearchNode;
};

export type SearchResult = {
  __typename?: 'SearchResult';
  currentNode?: Maybe<SearchNode>;
  nodes: NodeResult;
};

export type SelectOptionValues = {
  __typename?: 'SelectOptionValues';
  code: Scalars['String'];
  value: Array<OptionValue>;
};

export type SelectOptionValuesInput = {
  code: Scalars['String'];
  value: Array<OptionValueInput>;
};

export type Setting = {
  __typename?: 'Setting';
  cgtSettings?: Maybe<CgtSettings>;
  enableContentTranslation: Scalars['Boolean'];
  enableSearch: Scalars['Boolean'];
  jobList?: Maybe<Array<Scalars['String']>>;
  showPublish: Scalars['Boolean'];
  sidelSettings?: Maybe<SidelSettings>;
  theme?: Maybe<ThemeSetting>;
  trackPublishedDocuments?: Maybe<Scalars['Boolean']>;
};

export type ShortPricelist = {
  __typename?: 'ShortPricelist';
  families: Array<ShortPricelistFamily>;
  macroFamily: Scalars['String'];
  name: Scalars['String'];
  preset: Scalars['String'];
  shortPricelistId: Scalars['String'];
};

export type ShortPricelistFamily = {
  __typename?: 'ShortPricelistFamily';
  legend: Array<LegendItem>;
  name: Scalars['String'];
  prototypeIds: Array<Scalars['String']>;
};

export type SidelSettings = {
  __typename?: 'SidelSettings';
  boomi?: Maybe<SidelSettingsBoomi>;
  hierarchies?: Maybe<SidelSettingsHierarchy>;
};

export type SidelSettingsBoomi = {
  __typename?: 'SidelSettingsBoomi';
  password: Scalars['String'];
  username: Scalars['String'];
};

export type SidelSettingsHierarchy = {
  __typename?: 'SidelSettingsHierarchy';
  commercialItems: Scalars['String'];
  massiveCheckPrefix?: Maybe<Scalars['String']>;
};

export type Sort = {
  field: Scalars['String'];
  sort: SortOrder;
  type?: InputMaybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Subscription = {
  __typename?: 'Subscription';
  eventCreated: SubscriptionEvent;
  exportJobFinished: ExportJobFinishedInfo;
};


export type SubscriptionEventCreatedArgs = {
  hierarchyId?: InputMaybe<Scalars['String']>;
  nodeId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionExportJobFinishedArgs = {
  jobId?: InputMaybe<Scalars['String']>;
};

export type SubscriptionEvent = {
  __typename?: 'SubscriptionEvent';
  edits: Array<Edit>;
};

export type ThemeSetting = {
  __typename?: 'ThemeSetting';
  logo?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
};

export type Visibility = {
  __typename?: 'Visibility';
  operator?: Maybe<Scalars['String']>;
  visibilityRules?: Maybe<Array<VisibilityRule>>;
};

export type VisibilityInput = {
  operator: Scalars['String'];
  visibilityRules?: InputMaybe<Array<VisibilityRuleInput>>;
};

export type VisibilityRule = {
  __typename?: 'VisibilityRule';
  comparator: Scalars['String'];
  id: Scalars['String'];
  option: Scalars['String'];
  value: Scalars['String'];
};

export type VisibilityRuleInput = {
  comparator: Scalars['String'];
  id: Scalars['String'];
  option: Scalars['String'];
  value: Scalars['String'];
};

export type UpdateContentTypeGroupInput = {
  approveRoles?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  collapsed: Scalars['Boolean'];
  name: Array<NameObjectI>;
  repeatable: Scalars['Boolean'];
  roles?: InputMaybe<Scalars['String']>;
  showLevel?: InputMaybe<Scalars['String']>;
};
