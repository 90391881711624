import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useMemo,
    useState,
} from 'react';

type DirtyContextType = {
    isDirty: boolean;
    setIsDirty: Dispatch<SetStateAction<boolean>>;
};

const DirtyContext = createContext<DirtyContextType | null>(null);

const useDirtyContext = () => {
    const dirtyContext = useContext(DirtyContext);

    if (!dirtyContext) {
        throw new Error("Use 'DirtyContext' inside it's provider");
    }

    return dirtyContext;
};

type DirtyContextProviderProps = {
    children: ReactNode;
};

const DirtyContextProvider = ({ children }: DirtyContextProviderProps) => {
    const [isDirty, setIsDirty] = useState(false);

    const contextValue = useMemo(() => ({ isDirty, setIsDirty }), [isDirty]);

    return <DirtyContext.Provider value={contextValue}>{children}</DirtyContext.Provider>;
};

export { DirtyContextProvider, useDirtyContext };
