import { Setting } from '@src/__generated__/types';
import React from 'react';

export function getSettingContextDefaultValue(): Setting {
    return {
        enableSearch: false,
        showPublish: false,
        enableContentTranslation: false,
        cgtSettings: {
            skipPublish: false,
            longListsHierarchyObjectSchemaId: '',
            shortListFamilyHierarchyObjectSchemaId: '',
            sapProductHierarchyObjectSchemaId: '',
            glHierarchyObjectSchemaId: '',
            prototypeSetting: {
                modelCode: '',
                objectSchemaCode: '',
                glCodes: [],
                certificationStatuses: {
                    certified: '',
                    draft: '',
                    certifiedDraft: '',
                    error: '',
                    inCertification: '',
                },
                priceCodes: {
                    dn: {
                        key: '',
                    },
                    dn_cash_d: {
                        key: '',
                    },
                    tpa: {
                        key: '',
                    },
                    gp: {
                        key: '',
                    },
                    list_price: {
                        key: '',
                    },
                    lc: {
                        key: '',
                    },
                    gl_list_price: {
                        key: '',
                    },
                    gl_tpa: {
                        key: '',
                    },
                    dealer_gp: {
                        key: '',
                    },
                    dealer_markup: {
                        key: '',
                    },
                    dealer_mo: {
                        key: '',
                    },
                    dealer_ms: {
                        key: '',
                    },
                    dealer_price: {
                        key: '',
                    },
                    forced_gp: {
                        key: '',
                    },
                    forced_mo: {
                        key: '',
                    },
                    forced_tpa: {
                        key: '',
                    },
                    gp_ms: {
                        key: '',
                    },
                    gp_tpa: {
                        key: '',
                    },
                    markup: {
                        key: '',
                    },
                    mo: {
                        key: '',
                    },
                    ms: {
                        key: '',
                    },
                    standard_price_delta: {
                        key: '',
                    },
                    transport_costs: {
                        key: '',
                    },
                },
                prototypeHierarchyId: '',
            },
            hierarchies: {
                cGTModelHierarchyId: '',
                glHierarchyId: '',
                priceListHierarchyId: '',
                supplierHierarchyId: '',
                cGTModelCompetitorHierarchyId: '',
                cGTModelUsatoHierarchyId: '',
                glGenericHierarchyId: '',
                glCompetitorHierarchyId: '',
                glUsatoHierarchyId: '',
                glExFlottaHierarchyId: '',
                sapProductHierarchyId: '',
                sapProductCompetitorHierarchyId: '',
                marcheHierarchyId: '',
                longListsHierarchyId: '',
                commercialFamilyHierarchyId: '',
                shortListFamilyHierarchyId: '',
                sezioniSchedeHierarchyId: '',
            },
            fields: {
                orderTypeField: {
                    contentTypeCode: '',
                },
                lineaSapField: {
                    contentTypeCode: '',
                },
                marcaField: {
                    contentTypeCode: '',
                },
                sapProductHierarchyField: {
                    contentTypeCode: '',
                },
                biasedField: {
                    contentTypeCode: '',
                },
                extraValueSupportField: {
                    contentTypeCode: '',
                },
                incentiveVolumeField: {
                    contentTypeCode: '',
                },
                supplierField: {
                    contentTypeCode: '',
                },
                descriptionField: {
                    contentTypeCode: '',
                },

                suffixField: {
                    contentTypeCode: '',
                },
                supplierNameField: {
                    contentTypeCode: '',
                },
                priceListField: {
                    contentTypeCode: '',
                },
                typeField: {
                    contentTypeCode: '',

                    defaultValue: '',
                },
                itemsField: {
                    contentTypeCode: '',
                },
                c1Field: {
                    contentTypeCode: '',
                },
                c2Field: {
                    contentTypeCode: '',
                },
                c3Field: {
                    contentTypeCode: '',
                },
                gpRequestedField: {
                    contentTypeCode: '',
                },
                lcField: {
                    contentTypeCode: '',
                },
                baseProgramField: {
                    contentTypeCode: '',
                },
                svField: {
                    contentTypeCode: '',
                },
                cvaField: {
                    contentTypeCode: '',
                },
                conquestField: {
                    contentTypeCode: '',
                },
                dealerDiscountField: {
                    contentTypeCode: '',
                },
                certificationStatusField: {
                    contentTypeCode: '',
                },
                installedDealerNetField: {
                    contentTypeCode: '',
                },
                notInstalledDealerNetField: {
                    contentTypeCode: '',
                },
                cashDiscountField: {
                    contentTypeCode: '',
                },
                glBaseMachineField: {
                    contentTypeCode: '',
                },
                denominationField: {
                    contentTypeCode: '',
                },
                familyField: {
                    contentTypeCode: '',
                },
                modelCodeField: {
                    contentTypeCode: '',
                },
                selectionsField: {
                    contentTypeCode: '',
                },
                flagUseDN: {
                    contentTypeCode: '',
                },
            },
            groups: {
                pricingGroup: {
                    contentTypeGroupCode: '',
                },
                catSupportGroup: {
                    contentTypeGroupCode: '',
                },
                cgtSupportGroup: {
                    contentTypeGroupCode: '',
                },
                sapCharacteristicsGroup: {
                    contentTypeGroupCode: '',
                },
            },
            paths: {
                accessories: '',
                baseMachine: '',
                priceListValue: '',
                primaryPriceListValue: '',
                secondaryPriceListValue: '',
                itemsValue: '',
            },
        },
        jobList: [],
    };
}

export const SettingsContext = React.createContext<Setting>(getSettingContextDefaultValue());
